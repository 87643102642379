import { FormPaymentEnum, SalesModalityEnum } from '@/@types/Contracts';
import { z } from 'zod';

export const ContractProposalStepSchema = z.object({
  formPayment: z
    .object({
      label: z.string(),
      value: z.nativeEnum(FormPaymentEnum),
    })
    .nullable()
    .refine(data => data !== null && data !== undefined)
    .transform(data => data?.value),
  salesModality: z
    .object({
      label: z.string(),
      value: z.nativeEnum(SalesModalityEnum),
    })
    .nullable()
    .refine(data => data !== null && data !== undefined)
    .transform(data => data?.value),
  isDigitalSignature: z.boolean(),
  contractualDraftTemplateId: z.string().nullable(),
});

export type ContractProposalStepSchemaForm = z.infer<
  typeof ContractProposalStepSchema
>;
