/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { useUserStore } from '@/common/hooks/UserStore';

import { Toast } from '../common/components/Toast';

import { ENV } from './env';
import i18n from './i18n';

export const httpClient = axios.create({
  baseURL: ENV.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const urlsToIgnore401 = ['/user/get-user-info', '/oauth/token'];
const urlsToIgnoreUndefinedData = ['/user/get-user-info', '/oauth/token'];

httpClient.interceptors.request.use(
  config => {
    config.headers.locale = i18n.language || 'pt-BR';
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

httpClient.interceptors.request.use(
  config => {
    const { accountId } = useUserStore.getState();
    const shouldIgnore = urlsToIgnoreUndefinedData.some(url =>
      config.url?.includes(url),
    );

    if (accountId && !shouldIgnore) {
      config.headers.account = accountId;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(
  response => response,
  async error => {
    if (urlsToIgnore401.includes(error.config.url)) return;
    if (error.response.status === 401) {
      toast.info(
        ({ closeToast }) => (
          <Toast
            message={i18n.t('login/signIn:sessionExpired')}
            description={i18n.t('login/signIn:signInToContinue')}
            type="warning"
            handleClose={closeToast}
          />
        ),
        {
          toastId: 'session-expired',
        },
      );

      setTimeout(() => {
        flagsmith.logout();
        httpClient.defaults.headers.Authorization = '';
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        Cookies.remove('sessionState');
        Cookies.remove('dealerId');
        Cookies.remove('accountId');
        Cookies.remove('storeId');
        Cookies.remove('role');
        Cookies.remove('userType');
        window.location.reload();
        window.location.href = '/';
      }, 1500);
    }

    throw error;
  },
);
