import { IAxiosError } from '@/@types/AxiosError';
import { IGoogleRecaptchaInstance } from '@/@types/GoogleRecaptcha';
import { UserTypeEnum } from '@/@types/User';
import { zodResolver } from '@hookform/resolvers/zod';
import flagsmith from 'flagsmith';
import { useFlags } from 'flagsmith/react';
import Cookies from 'js-cookie';
import { Eye, EyeOff, Lock } from 'lucide-react';
import { useLayoutEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import QToolLogo from '@/assets/icons/qtool.svg';
import RecaptchaIcon from '@/assets/icons/recaptcha.svg';
import Button from '@/common/components/Button';
import { Checkbox } from '@/common/components/Checkbox';
import { LanguageMenuDropdown } from '@/common/components/LanguageMenuDropDown';
import { TextInput } from '@/common/components/TextInput';
import { useAppRouter } from '@/common/hooks/useAppRouter';
import { useUserStore } from '@/common/hooks/UserStore';
import { useToast } from '@/common/hooks/useToast';
import { getUserInfo } from '@/common/services/getUserInfo';
import { links } from '@/common/utils/links';
import { ENV } from '@/config/env';
import { ProtectedRoutes } from '@/routes/protected.routes';

import { useLogin } from '../../hooks/useLogin';
import { useLoginStatusStore } from '../../hooks/useLoginStatusStore';
import { SignInForm, SignInSchema } from '../../schemas/SignInSchema';

import { AcceptTermsOfUse } from './components/AcceptTermsOfUse';
import * as style from './styles';

export default function SignIn() {
  const [loadingCaptchaToken, setLoadingCaptchaToken] = useState(false);
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { toast } = useToast();
  const isAllowSignIn = useFlags(['allow_sign_in']);
  const { t } = useTranslation(['common', 'login/signIn']);
  const { signIn } = useLogin();
  const navigate = useNavigate();
  const { saveAccountUser, saveDealerUser, saveUserStore, saveUserAccount } =
    useUserStore();
  const { changeRouter } = useAppRouter();
  const { setLoginStatus } = useLoginStatusStore();

  const captchaRef = useRef<IGoogleRecaptchaInstance>(null);

  const hookForm = useForm<SignInForm>({
    mode: 'onBlur',
    resolver: zodResolver(SignInSchema),
    defaultValues: {
      rememberData: true,
    },
  });

  const verifyCaptcha = async () => {
    try {
      if (!captchaRef?.current) {
        toast.warn(t('login/signIn:reCaptchaHumanError'));
        return null;
      }
      setLoadingCaptchaToken(true);
      captchaRef?.current?.reset();
      const reCaptchaToken = await captchaRef?.current?.executeAsync();
      return reCaptchaToken;
    } catch (error) {
      toast.error(t('login/signIn:reCaptchaAPIError'));
      return null;
    } finally {
      setLoadingCaptchaToken(false);
    }
  };

  const handleSignIn = async (data: SignInForm) => {
    const reCaptchaToken = await verifyCaptcha();

    if (!reCaptchaToken) {
      hookForm.setError('reCaptcha', {
        message: 'reCaptcha error',
      });
      return;
    }

    setLoadingSignIn(true);
    try {
      await signIn({
        ...data,
      });

      const response = await getUserInfo();
      const user = response.data;

      if (!user.isTermAccepted) {
        toast.warn(t('login/signIn:termsOfUseNotAcceptedYet'));
        window.HSOverlay.open(
          document.getElementById('hs-modal-terms-of-use')!,
        );
        return;
      }

      await flagsmith.identify(user?.email, { userType: user.userType });
      await flagsmith.getFlags();
      flagsmith.startListening(
        1000 * 60 * 15, // 15 minutes
      );

      if (user.userType === UserTypeEnum.ACCOUNT) {
        const userAccounts = saveAccountUser(user);

        Cookies.set('userType', UserTypeEnum.ACCOUNT);

        if (userAccounts.length === 1) {
          const { accountId, role } = userAccounts[0];

          Cookies.set('accountId', accountId);
          Cookies.set('role', role);

          await flagsmith.setTrait('role', role);

          saveUserAccount({
            accountId,
            role,
          });
          changeRouter(ProtectedRoutes);
          navigate(links.panel);
          return;
        }

        navigate(links.login.accountSelection);
      } else {
        const userStores = saveDealerUser(user);
        Cookies.set('userType', UserTypeEnum.DEALER);

        if (userStores.length === 1) {
          const { dealerId, id, role, dealerStoreUserId, Dealer } =
            userStores[0];
          Cookies.set('accountId', Dealer.Account.id);
          Cookies.set('dealerId', dealerId);
          Cookies.set('storeId', id);
          Cookies.set('role', role);

          await flagsmith.setTrait('dealerId', dealerId);
          // USUÁRIOS "STORE" POSSUI A VARIÁVEL `storeUser` = `true`
          await flagsmith.setTrait('storeUser', !!dealerStoreUserId);
          await flagsmith.setTrait('role', role);

          saveUserAccount({
            accountId: Dealer.Account.id,
            role,
          });
          saveUserStore({
            dealerId,
            storeId: id,
            role,
          });
          changeRouter(ProtectedRoutes);
          navigate(links.panel);
          return;
        }

        navigate(links.login.storeSelection);
      }
    } catch (error) {
      const requestError = error as IAxiosError;
      if (
        requestError &&
        requestError?.response?.data?.friendlyMessage === 'invalidCredentials'
      ) {
        toast.error(
          t(`login/signIn:${requestError?.response?.data?.friendlyMessage}`),
        );
      } else {
        toast.error(
          requestError?.response?.data?.friendlyMessage ||
            t('login/signIn:signInError'),
        );
      }
    } finally {
      setLoadingSignIn(false);
      setLoginStatus(true);
    }
  };

  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!window?.recaptchaOptions) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.assign(window.recaptchaOptions, {
      nonce: document
        ?.querySelector("meta[name='csp-nonce']")
        ?.getAttribute('content'),
    });
  }, []);

  useLayoutEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [loadingSignIn]);

  return (
    <>
      <button
        data-hs-overlay="#hs-modal-terms-of-use"
        type="button"
        className="hidden"
      />
      <main className={style.main()}>
        <header className={style.header()}>
          <img src={QToolLogo} alt="QTool" />
        </header>

        <FormProvider {...hookForm}>
          <form
            className={style.form()}
            onSubmit={hookForm.handleSubmit(handleSignIn)}
          >
            <TextInput
              size="lg"
              name="username"
              placeholder={t('login/signIn:username')}
            />

            <TextInput
              size="lg"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder={t('login/signIn:password')}
              TrailingIcon={
                showPassword ? (
                  <Eye className="text-neutral-500" size={16} />
                ) : (
                  <EyeOff className="text-neutral-500" size={16} />
                )
              }
              handleClickTrailingIcon={handleToggleShowPassword}
            />

            <fieldset className={style.options()}>
              <Checkbox
                name="rememberData"
                label={t('login/signIn:rememberMe')}
                extraClassNames={style.checkbox()}
              />

              <div className={style.divider()} />

              <div className={style.wrapperLock()}>
                <Lock size={14} className={style.lock()} strokeWidth={3} />

                <Link
                  to={`/forgot-password`}
                  className={style.forgotPassword()}
                >
                  {t('login/signIn:forgotPassword')}
                </Link>
              </div>
            </fieldset>

            <fieldset className={style.reCaptchaContainer()}>
              <div
                className={style.reCaptcha({
                  hasError: !!hookForm.formState.errors.reCaptcha,
                })}
              >
                <Checkbox
                  name="reCaptcha"
                  label={t('common:recaptchaMessage')}
                  enableShowError={false}
                />

                <img src={RecaptchaIcon} alt="reCAPTCHA" />
              </div>

              {hookForm.formState.errors.reCaptcha && (
                <p className={style.reCaptchaError()}>
                  {t('login/signIn:reCaptchaError')}.
                </p>
              )}
            </fieldset>

            <Button
              type="submit"
              isLoading={loadingSignIn || loadingCaptchaToken}
              text={t('login/signIn:signIn')}
              disabled={!isAllowSignIn.allow_sign_in.enabled}
              className={style.button()}
            />
          </form>
        </FormProvider>

        <footer className={style.footer()}>
          <a
            href={ENV.VITE_TERMS_OF_USE_URL}
            target="_bank"
            className={style.privacyPolicy()}
          >
            {t('common:termsOfUseAndPrivacyPolicy')}
          </a>

          <p className={style.copyright()}>
            &copy; Copyright By IVECO - {t('login/signIn:allRightsReserved')}
          </p>
          <LanguageMenuDropdown />
        </footer>
      </main>

      <ReCAPTCHA
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={captchaRef}
        sitekey={ENV.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
        size="invisible"
        className="hidden"
      />

      <AcceptTermsOfUse recoverFormData={hookForm.getValues} />
    </>
  );
}
