import { IAppNavigation } from '@/@types/AppNavigation';
import { UserRoleEnum, UserTypeEnum } from '@/@types/User';
import Cookies from 'js-cookie';
import { ChevronRight, LayoutGrid, LogOut, Settings } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Avatar from '@/common/components/Avatar';
import { FeatureFlag } from '@/common/components/FeatureFlag';
import { Tooltip } from '@/common/components/Tooltip';
import { useGetUserInfo } from '@/common/hooks/useGetUserInfo';
import { useRoleTranslation } from '@/common/hooks/useRoleTranslation';
import { useUserStore } from '@/common/hooks/UserStore';
import { links } from '@/common/utils/links';
import { useLogin } from '@/modules/login/hooks/useLogin';

import { useCollapseSidebar } from '../..';
import { NavItem } from '../NavItem';

import * as style from './styles';

export function Config() {
  const { signOut } = useLogin();
  const { t } = useTranslation(['sidebar/fixedMenus', 'settings/menus']);
  const { user, isLoadingUser } = useGetUserInfo();
  const {
    role,
    person,
    saveAccountUser,
    saveDealerUser,
    saveUserStore,
    saveUserAccount,
    store,
    userAccount,
    userStores,
  } = useUserStore();
  const roleTranslated = useRoleTranslation(role);
  const { collapsed } = useCollapseSidebar();
  const { pathname } = useLocation();
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (user?.userType === UserTypeEnum.ACCOUNT) {
      saveAccountUser(user);
      const accountId = Cookies.get('accountId')!;
      const roleFromCookies = Cookies.get('role') as UserRoleEnum;

      saveUserAccount({
        accountId,
        role: roleFromCookies,
      });
    } else if (user?.userType === UserTypeEnum.DEALER) {
      saveDealerUser(user);
      const dealerId = Cookies.get('dealerId')!;
      const storeId = Cookies.get('storeId')!;
      const accountId = Cookies.get('accountId')!;
      const roleFromCookies = Cookies.get('role') as UserRoleEnum;

      saveUserAccount({
        accountId,
        role: roleFromCookies,
      });
      saveUserStore({
        dealerId,
        storeId,
        role: roleFromCookies,
      });
    }
  }, [user, saveAccountUser, saveDealerUser, saveUserStore, saveUserAccount]);

  const navigation: IAppNavigation = {
    key: 'settings',
    flag: 'settings_sidebar',
    title: t('settings/menus:settings'),
    titleIcon: ChevronRight,
    icon: Settings,
    link: isWideScreen ? links.settings.users : links.settings.settingsOptions,
    activePaths: [
      links.settings.contractTemplate,
      links.settings.dealer,
      links.settings.globalConfig,
      links.settings.materials,
      links.settings.models,
      links.settings.products,
      links.settings.planProductsParts,
      links.settings.plans,
      links.settings.store,
      links.settings.chassis,
      links.settings.priceTable,
      links.settings.campaigns,
      links.settings.monetaryVariation,
      links.settings.ipca,
      links.settings.account,
    ],
  };
  const currentPath = pathname.split('/')[1];

  return (
    <section className={style.container()}>
      <div className={style.horizontalDivider()} />

      <div className={style.content()}>
        {isLoadingUser && (
          <div className={style.avatarSkeletonContainer()}>
            <div className={style.avatarSkeleton()} />
            <div className={style.textSkeletonContainer()}>
              <div className={style.nameSkeleton()} />
              <div className={style.roleSkeleton()} />
            </div>
          </div>
        )}

        {!isLoadingUser && user && (
          <Link
            to={
              isWideScreen
                ? links.userProfile.profile
                : links.userProfile.userProfileOptions
            }
            className={style.user({
              isSelected: currentPath === `user-profile`,
            })}
          >
            <Avatar
              url={user?.UserPreference?.avatarUrl}
              name={person?.name || user?.displayName}
              size="small"
            />

            <div className={style.description()}>
              <span className={style.name()}>{user?.displayName}</span>

              <span className={style.role()}>{roleTranslated}</span>
              <ChevronRight size={12} className={style.profileChevronRight()} />
            </div>
          </Link>
        )}

        <FeatureFlag flag="modal_store_selection">
          <Tooltip message={t('stores')} inactive={!collapsed}>
            <button
              data-hs-overlay="#hs-system-access-modal"
              type="button"
              className={style.modalButton()}
              disabled={userStores?.length === 1}
            >
              <LayoutGrid size={18} className="text-brand-pure" />

              <div className={style.storesButtonTextContainer()}>
                <Tooltip message={store?.displayName}>
                  <span className={style.storesButtonText()}>
                    {store?.displayName}
                  </span>
                </Tooltip>

                <ChevronRight size={12} className={style.storesButtonIcon()} />
              </div>
            </button>
          </Tooltip>
        </FeatureFlag>

        <FeatureFlag flag="modal_account_selection">
          <Tooltip message={t('accounts')} inactive={!collapsed}>
            <button
              data-hs-overlay="#hs-system-accounts-modal"
              type="button"
              className={style.modalButton()}
              disabled={userStores?.length === 1}
            >
              <LayoutGrid size={18} className="text-brand-pure" />

              <div className={style.storesButtonTextContainer()}>
                <Tooltip message={userAccount?.Account?.title}>
                  <span className={style.storesButtonText()}>
                    {userAccount?.Account?.title}
                  </span>
                </Tooltip>

                <ChevronRight size={12} className={style.storesButtonIcon()} />
              </div>
            </button>
          </Tooltip>
        </FeatureFlag>

        <nav className={style.content()}>
          <NavItem data={navigation} />
        </nav>

        <Tooltip message={t('logout')} inactive={!collapsed}>
          <button type="button" className={style.button()} onClick={signOut}>
            <LogOut size={18} className={style.logoutIcon()} />
            <span className={style.buttonText()}>{t('logout')}</span>
          </button>
        </Tooltip>
      </div>
    </section>
  );
}
