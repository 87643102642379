import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import ErrorBoundary from '@/common/components/ErrorBoundary';
import { links } from '@/common/utils/links';
import { LoginLayout } from '@/layouts/Login';

import SignIn from '../pages/SignIn';

const AccountSelection = lazy(() => import('../pages/AccountSelection'));
const StoreSelection = lazy(() => import('../pages/StoreSelection'));
const ActivateUser = lazy(() => import('../pages/ActivateUser'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));

export const LoginRoutes: RouteObject[] = [
  {
    element: <LoginLayout />,
    children: [
      {
        path: links.login.main,
        element: <SignIn />,
      },
      {
        path: links.login.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: links.login.resetPassword,
        element: <ResetPassword />,
      },
      {
        path: links.login.activateUser,
        element: <ActivateUser />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: links.login.storeSelection,
    element: <StoreSelection />,
  },
  {
    path: links.login.accountSelection,
    element: <AccountSelection />,
  },
  {
    path: '*',
    element: <LoginLayout />,
    children: [
      {
        path: '*',
        index: true,
        element: <SignIn />,
      },
    ],
  },
];
